import React from "react";
import LoginForm from "../components/Login/LoginForm";

const Login = ({
  connectToSocket = () => {},
  setError = () => {},
  error = "",
}) => {
  return (
    <div className="login">
      <LoginForm
        connectToSocket={connectToSocket}
        setError={setError}
        error={error}
      />
    </div>
  );
};

export default Login;
