import React from "react";
import { Modal, Table } from "react-bootstrap";

const OrderModal = ({
  show = false,
  onHide = () => {},
  config = {},
  data = [],
  footerRows = [],
  order = {},
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop={false}
      className="custom-modal"
    >
      <Modal.Body>
        <div className="show-order">
          <h1 className="show-order__title">Order #{order.pk}</h1>
          <p className="show-order__customer">
            Customer: {order.customer.firstName + " " + order.customer.lastName}
          </p>
          <p className="show-order__customer">Outlet: {order.outlet.name}</p>
        </div>
        <Table striped className="data-table">
          <thead>
            <tr>
              {config.mapper.map(({ title }, index) => (
                <th key={index + "header"}>{title}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.map((entry, index) => (
              <tr key={index + "outer"}>
                {config.mapper.map(
                  ({ title, dataKey, render, onClick, clickable }, index) => (
                    <td
                      className={
                        onClick && (!clickable || clickable(entry))
                          ? "clickable"
                          : ""
                      }
                      key={index + "inner"}
                      onClick={onClick ? () => onClick(entry) : () => {}}
                    >
                      {(dataKey && entry[dataKey]) || (render && render(entry))}
                    </td>
                  )
                )}
              </tr>
            ))}

            {footerRows?.map((entry, index) => (
              <tr key={index}>
                {entry.map((data) => (
                  <th>{data}</th>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default OrderModal;
