import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import { routes } from "../../constants";

const Ads = () => {
  const [ads, setAds] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    adaptiveWidth: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  useEffect(() => {
    axios.get(routes.listAds).then((response) => {
      if (response.status && response.status >= 200 && response.status < 300) {
        let images = response.data.results.map((item) => item.picture);
        setAds(images);
      }
    });
  }, []);

  return (
    <div className="ads-area">
      <Slider {...settings}>
        {ads.map((ad, index) => (
          <div key={index} className="ads-area__ad-container">
            <img src={ad} alt={"ad" + (+index + 1)} className="ads-area__ad" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Ads;
