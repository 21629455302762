import React, { useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";

const MessageModal = ({
  show = false,
  onHide = () => {},
  message = {},
  timer = null,
}) => {
  const timerRef = useRef(null);

  const onShow = () => {
    handleTimer();
  };

  useEffect(() => {
    handleTimer();
  }, [timer]);

  const handleTimer = () => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
    } else {
      if (timer !== null) {
        timerRef.current = setTimeout(() => {
          onHide();
        }, timer);
      }
    }
  };

  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={onHide}
      backdrop={false}
      className="custom-modal"
    >
      <Modal.Body>
        <p
          className={
            "modal-title" +
            (message?.type ? " modal-title--" + message.type : "")
          }
        >
          {message?.text?.title}
        </p>
        <p className="modal-message">{message?.text?.subtitle}</p>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;
