import React, { useState, useEffect, useRef } from "react";
import OrderModal from "../Utils/OrderModal";
import MessageModal from "../Utils/MessageModal";
import Logo from "../../assets/images/logos/logo.png";
import NoImage from "../../assets/images/no-image.jpg";
import { promoTypes } from "../Utils/promoTypes";
import { useNavigate } from "react-router-dom";

const Order = ({
  socket = null,
  setLoggedIn = () => {},
  disconnectSocket = () => {},
}) => {
  const restingTitle = "Scan your RFID to check your balance";
  const restingSubtitle = "";
  const [activeOrder, setActiveOrder] = useState(null);
  const [total, setTotal] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState({});
  const [scanBracelet, setScanBracelet] = useState("");
  const [defaultTitle, setDefaultTitle] = useState(restingTitle);
  const [defaultSubtitle, setDefaultSubtitle] = useState(restingSubtitle);
  const [showOrder, setShowOrder] = useState(null);
  const [userPromos, setUserPromos] = useState([]);
  const [rfid, setRfid] = useState("");
  const rfidTimeout = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (socket !== null) {
      handleSocket();
    }
  }, [socket]);

  useEffect(() => {
    window.addEventListener("resize", handleViewPortSize);
    handleViewPortSize();
    return () => {
      window.removeEventListener("resize", handleViewPortSize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", handleGlobalScan);
    handleRfidReset();
    handleRfidSubmit();
    return () => {
      window.removeEventListener("keypress", handleGlobalScan);
    };
  }, [rfid]);

  const handleRfidReset = () => {
    if (rfid.length === 0) {
      if (rfidTimeout.current) {
        clearTimeout(rfidTimeout.current);
      }
    } else {
      if (rfidTimeout.current === null) {
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      } else {
        clearTimeout(rfidTimeout.current);
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      }
    }
  };

  const handleRfidSubmit = () => {
    if (rfid.length === 10) {
      let customerRfid = rfid;
      sendRfidToOG("rfid", customerRfid);
      setRfid("");
    }
  };

  const handleViewPortSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleGlobalScan = (e) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      setRfid((current) => {
        return current + e.key;
      });
    }
  };

  const sendRfidToOG = (type, rfid) => {
    if (socket !== null && socket.readyState == 1) {
      socket.send(JSON.stringify({ type, message: rfid }));
    }
  };

  useEffect(() => {
    scrollOrderToBottom();
  }, [scanBracelet]);

  const handleSocket = () => {
    socket.onmessage = (event) => {
      let data = JSON.parse(event.data);
      if (data.type === "change") {
        data = data.message;
        setActiveOrder(data.items.length === 0 ? null : data.items);
        setTotal(data.items.length === 0 ? 0 : data.total);
        scrollOrderToBottom();
      } else if (data.type === "end") {
        setActiveOrder(null);
        setTotal(0);
      } else if (data.type === "popup") {
        handleMessagePopupClose();
        setScanBracelet("");
        if (data.message !== "close") {
          setMessage(data.message);
          setShowPopup(true);
        }
      } else if (data.type === "popup-special") {
        handleMessagePopupClose();
        setScanBracelet(data.message.text);
      } else if (data.type === "globalScan") {
        if (data.message.balance === "") {
          setDefaultTitle(restingTitle);
          setDefaultSubtitle(restingSubtitle);
          setUserPromos([]);
        } else {
          setActiveOrder(null);
          setShowPopup(false);
          setDefaultTitle("Your balance is " + data.message.balance);
          setUserPromos(data.message.promos);
        }
      } else if (data.type === "order") {
        if (data.message === "") {
          setShowOrder(null);
        } else {
          setShowOrder(data.message);
        }
      } else if (data.type === "refresh") {
        handleMessagePopupClose();
        setShowOrder(null);
        socket.send(JSON.stringify({ type: "fetch", message: "" }));
      } else if (data.type === "info") {
        if (data.message === "success") {
          setDefaultTitle("Thank you!");
          setDefaultSubtitle("Next Customer");
          setTimeout(() => {
            setDefaultTitle(restingTitle);
            setDefaultSubtitle(restingSubtitle);
          }, 5000);
        }
      }
    };
  };

  const handleMessagePopupClose = () => {
    setShowPopup(false);
    setMessage({});
  };

  const handleOrderClose = () => {
    setShowOrder(null);
  };

  useEffect(() => {
    if (socket !== null && socket.readyState == 1) {
      socket.send(JSON.stringify({ type: "fetch", message: "" }));
    }
  }, [socket]);

  const scrollOrderToBottom = () => {
    let order = document.getElementsByClassName("order__body")[0];
    order.scrollBy(0, order.scrollHeight);
  };

  const logout = () => {
    localStorage.removeItem("vendorId");
    setLoggedIn(false);
    disconnectSocket();
    navigate("/login");
  };

  return (
    <div className="order">
      <div className="logo-container">
        <img src={Logo} alt="" className="logo" onClick={() => logout()} />
      </div>
      <div className="order__body">
        {activeOrder === null && !showPopup && showOrder === null && (
          <div className="order__display-message order__message-modal">
            {defaultTitle && (
              <h1 className="order__message-title">{defaultTitle}</h1>
            )}
            {defaultSubtitle && (
              <h3 className="order__message-subtitle">{defaultSubtitle}</h3>
            )}
            {userPromos.length !== 0 && (
              <div className="promos">
                {userPromos.map((item, index) => (
                  <React.Fragment key={index}>
                    {index <= 2 ? (
                      <div className="promos__item-container">
                        <img
                          src={item.picture || NoImage}
                          alt=""
                          className="promos__item-image"
                        />
                        <p className="promos__item-name">{item.name}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ))}
                {userPromos.length > 3 && (
                  <div
                    className="promos__item-container"
                    style={{ flexBasis: "unset", margin: "0" }}
                  >
                    <p className="promos__item-name">
                      + {userPromos.length - 3}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {activeOrder !== null && !showPopup && !showOrder && (
          <React.Fragment>
            <h1 className="order__title">Order</h1>
            <div className="order__receipt">
              {activeOrder?.map((item, index) => (
                <div className="order__receipt-item" key={index}>
                  <div className="order__receipt-item-name-container">
                    <p className="order__receipt-item-name">
                      {item?.item?.name}
                    </p>
                    {item?.item?.isSpecial && (
                      <p className="order__receipt-item-offer">
                        {promoTypes[item?.item?.promo?.type] ===
                          "buy_x_get_y" && "free"}
                        {(promoTypes[item?.item?.promo?.type] === "universal" ||
                          promoTypes[item?.item?.promo?.type] ===
                            "promo_code") &&
                          "Discount " +
                            item?.item?.promo?.discountPercent +
                            "%"}
                      </p>
                    )}
                  </div>
                  <p className="order__receipt-item-quantity">
                    {item?.quantity}
                  </p>
                  <p className="order__receipt-item-price">
                    {item?.item?.isSpecial ? (
                      <React.Fragment>
                        <div style={{ textDecoration: "line-through" }}>
                          {+(item?.item?.oldPrice / 100) * +item?.quantity} EGP
                        </div>
                        <div className="order__receipt-item-offer">
                          {item?.item?.price == 0
                            ? 0
                            : +(item?.item?.price / 100) * +item?.quantity}{" "}
                          EGP
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {+(item?.item?.price / 100) * +item?.quantity} EGP
                      </React.Fragment>
                    )}
                  </p>
                </div>
              ))}
              <div className="order__receipt-total">
                <p className="order__receipt-total-text">Total</p>
                <p className="order__receipt-total-value">{total} EGP</p>
              </div>
            </div>
          </React.Fragment>
        )}
        {showPopup && (
          <MessageModal
            show={showPopup}
            onHide={handleMessagePopupClose}
            message={message}
            timer={message?.timer || null}
          />
        )}
        {showOrder !== null && (
          <React.Fragment>
            <OrderModal
              show={showOrder !== null}
              onHide={handleOrderClose}
              config={{
                keyField: "pk",
                mapper: [
                  {
                    title: "Item",
                    dataKey: "name",
                  },
                  {
                    title: "Price",
                    render: (orderItem) =>
                      `${orderItem.priceAtPurchase / 100} EGP`,
                  },
                  {
                    title: "Quantity",
                    dataKey: "quantity",
                  },
                  {
                    title: "Sum",
                    render: (orderItem) =>
                      `${
                        (orderItem.priceAtPurchase * orderItem.quantity) / 100
                      } EGP`,
                  },
                ],
              }}
              data={showOrder.orderItems}
              footerRows={[["Total", "", "", `${showOrder.total / 100} EGP`]]}
              order={showOrder}
            />
          </React.Fragment>
        )}
        {scanBracelet && (
          <div className="order__display-message">
            <p className="modal-message bracelet-message">{scanBracelet}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Order;
