import React, { useState, useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { socketUrl } from "./constants";

const App = () => {
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const healthCheckTimer = useRef(null);
  const [socket, setSocket] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("vendorId")) {
      setLoggedIn(true);
      connectToSocket();
    }
  }, [loggedIn]);

  useEffect(() => {
    handleSocketConnectionStatus();
  }, [socket, loggedIn]);

  const connectToSocket = (id = null) => {
    let vendorId;
    if (id == null) {
      vendorId = localStorage.getItem("vendorId");
    } else {
      vendorId = id;
    }
    if(vendorId){
      const sock = new WebSocket(`${socketUrl}/${vendorId}/`);
      sock.onopen = () => {
        setSocket(sock);
        localStorage.setItem("vendorId", vendorId);
        setLoggedIn(true);
        navigate("/ffs");
      };
      sock.onerror = () => {
        setError("something went wrong");
      };
    }
  };

  const handleSocketConnectionStatus = () => {
    if (healthCheckTimer.current) {
      clearInterval(healthCheckTimer.current);
    }
    if (loggedIn) {
      healthCheckTimer.current = setInterval(() => {
        disconnectSocket();
        connectToSocket();
      }, 30000);
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.close();
    }
  };

  return (
    <Routes>
      {socket !== null && (
        <Route
          exact
          path="/ffs"
          element={
            <Home
              socket={socket}
              setLoggedIn={setLoggedIn}
              disconnectSocket={disconnectSocket}
            />
          }
        />
      )}
      <Route
        exact
        path="/login"
        element={
          <Login
            connectToSocket={connectToSocket}
            setError={setError}
            error={error}
          />
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default App;
