import React, { useState } from "react";

const LoginForm = ({
  connectToSocket = () => {},
  error = "",
  setError = () => {},
}) => {
  const [id, setId] = useState("");

  const handleLogin = (id) => {
    setError("");
    connectToSocket(id);
  };

  return (
    <form
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin(id);
      }}
    >
      <h1 className="login-form__title">Login</h1>
      {error && <p className="error-message">{error}</p>}
      <input
        type="text"
        value={id}
        onChange={(e) => setId(e.target.value)}
        className="login-form__input"
        required
      />
      <button type="submit" className="login-form__submit">
        Login
      </button>
    </form>
  );
};

export default LoginForm;
