import React from "react";
import Ads from "../components/Home/Ads";
import Order from "../components/Home/Order";

const Home = ({
  socket = null,
  setLoggedIn = () => {},
  disconnectSocket = () => {},
}) => {
  return (
    <div className="home">
      <Ads />
      <Order
        socket={socket}
        setLoggedIn={setLoggedIn}
        disconnectSocket={disconnectSocket}
      />
    </div>
  );
};

export default Home;
